import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { injectIntl } from 'react-intl';
import {
  Grid,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import * as styles from './connect.module.scss';

const Connect = ({ intl: { formatMessage } }) => {
  const theme = useTheme();
  const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <div className={styles.connect}>
      <Grid container spacing={3}>
        <Grid item container xs={12} order={1} justifyContent="center">
          <Stack spacing={2} width={[1, 1, 0.8, 0.7, 0.6]} mb={2}>
            <Typography variant="h3" className={styles.title}>
              {formatMessage({ id: 'professionalJobConnectTitle' })}
            </Typography>
            <Typography variant="h6" className={styles.content}>
              {formatMessage({ id: 'professionalJobConnectContent' })}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={3} xl={4} order={2} alignSelf="center">
          <Grid container justifyContent="center">
            {matchesLgDown ? (
              <StaticImage
                src="../../../images/connect_1.webp"
                alt="Connect 1"
                className={styles.full_img}
                placeholder="blurred"
                loading="lazy"
                layout="constrained"
                formats={["auto", "webp", "avif"]}
                objectFit="contain"
              />
            ) : (
              <StaticImage
                src="../../../images/connect_1.webp"
                alt="Connect 1"
                aspectRatio={575 / 705}
                placeholder="blurred"
                loading="lazy"
                layout="constrained"
                formats={["auto", "webp", "avif"]}
                objectFit="contain"
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={9} xl={8} order={3} alignSelf="center">
          <Stack spacing={1.5}>
            <Typography variant="h6" className={styles.connect__paragraph}>
              {formatMessage({ id: 'professionalJobConnect1Paragraph1' })}
            </Typography>
            <Typography variant="h6" className={styles.connect__paragraph}>
              {formatMessage({ id: 'professionalJobConnect1Paragraph2' })}
            </Typography>
            <Typography variant="h6" className={styles.connect__paragraph}>
              {formatMessage({ id: 'professionalJobConnect1Paragraph3' })}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

Connect.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Connect);
