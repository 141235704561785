import React from 'react';
import { Banner, Connect } from './components';
import { useIntl } from 'react-intl';

const ProfessionalsPage = () => {
  return (
    <main>
      <Banner />
      <Connect />
    </main>
  );
};

export default ProfessionalsPage;


export const Head = () => {
  const intl = useIntl();
  return (
    <>
      <title>{intl.formatMessage({ id: 'professionalsAreaSeoTitle' })}</title>
      <meta
        name="description"
        content={intl.formatMessage({ id: 'professionalsAreaSeoDescription' })}
      />
      <meta
        name="keywords"
        content={intl.formatMessage({ id: 'professionalsAreaSeoKeywords' })}
      />
      <meta name="robots" content={intl.formatMessage({ id: 'seoRobots' })} />
      <link rel="canonical" href={intl.formatMessage({ id: 'professionalsAreaOgUrl' })} />
      <meta
        property="og:title"
        content={intl.formatMessage({ id: 'professionalsAreaSeoTitle' })}
      />
      <meta
        property="og:description"
        content={intl.formatMessage({ id: 'professionalsAreaSeoDescription' })}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={intl.formatMessage({ id: 'professionalsAreaOgUrl' })}
      />
      <meta
        property="og:image"
        content={intl.formatMessage({ id: 'ogImage' })}
      />
      <meta
        name="twitter:card"
        content={intl.formatMessage({ id: 'twitterCard' })}
      />
      <meta
        name="twitter:title"
        content={intl.formatMessage({ id: 'professionalsAreaSeoTitle' })}
      />
      <meta
        name="twitter:description"
        content={intl.formatMessage({ id: 'professionalsAreaSeoDescription' })}
      />
      <meta
        name="twitter:image"
        content={intl.formatMessage({ id: 'twitterImage' })}
      />
    </>
  );
};
